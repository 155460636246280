/* import __COLOCATED_TEMPLATE__ from './help-center-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import type ArticleContent from 'embercom/models/articles/article-content';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import { isPresent } from '@ember/utils';
import { NEW_SELECTOR } from '../side-panel/help-center-selector';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import type ArticleMultilingual from 'embercom/models/article-multilingual';

interface Args {
  activeContent: ArticleContent;
  article: ArticleMultilingual;
  publishActiveContent: () => Promise<void>;
  onClose: () => void;
}

export type selectedTuples = Map<string | undefined, string | undefined>;

export interface HelpCenterSelection {
  selectedTuples: selectedTuples | undefined;
}

enum HelpCenterOptions {
  InHelpCenter = 'in-help-center',
  UnlistedHelpCenter = 'unlisted-help-center',
}

export default class HelpCenterModal extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare helpCenterService: any;

  @tracked declare selection: HelpCenterSelection;
  @tracked showHelpCenterModal = false;
  @tracked declare selected: HelpCenterOptions;
  @tracked modalHidden = false;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.selected = HelpCenterOptions.InHelpCenter;
    this.selection = { selectedTuples: undefined };
  }

  get selectedOneHelpCenterAndCollection() {
    return this.selection.selectedTuples?.size === 1;
  }

  get selectedHelpCenters(): Array<string> {
    // @ts-ignore isPresent's type definition is not imported correctly.
    return Array.from(this.selection?.selectedTuples.keys()).filter(
      (id) => isPresent(id) && id !== NEW_SELECTOR,
    );
  }

  get hasSingleHelpCenter() {
    return this.helpCenterService.allSites.length === 1;
  }

  get shouldShowNotLiveStatus() {
    return this.hasSingleHelpCenter && !this.helpCenterService.allSites.firstObject.websiteTurnedOn;
  }

  get selectedHelpCenter(): string {
    let helpCenterId = this.hasSingleHelpCenter
      ? this.helpCenterService.allSites.firstObject.id
      : this.selectedHelpCenters[0];
    let helpCenter = this.store.peekRecord('help-center-site', helpCenterId);
    return helpCenter.name;
  }

  get publishButtonLabel() {
    return this.intl.t('knowledge-hub.content-editor.article-content.modal.publish-article');
  }

  get disablePublishButton() {
    return (
      this.selected === HelpCenterOptions.InHelpCenter &&
      (this.selection.selectedTuples === undefined ||
        this.selection.selectedTuples?.size === 0 ||
        this.selection.selectedTuples?.has(NEW_SELECTOR))
    );
  }

  @action
  setModalHidden(value: boolean) {
    this.modalHidden = value;
  }

  @action
  updateSelection(selection: HelpCenterSelection) {
    this.selection = selection;
  }

  @action
  publish() {
    if (this.selected === HelpCenterOptions.UnlistedHelpCenter) {
      this.args.publishActiveContent();
      this.args.onClose();
    } else {
      this.updateArticle();
      this.args.publishActiveContent();
      this.args.onClose();
    }
  }

  @action
  cancel() {
    // The publish modal only shows if the article is not in a collection, so we can just clear it when cancelling, rather than needing to reset it.
    this.args.article.inCollections.clear();
    this.args.onClose();
  }

  @action
  async updateArticle() {
    this.knowledgeHubEditorService.updateHelpCenterAndCollection(this.args.article);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::Edit::HelpCenterModal': typeof HelpCenterModal;
    'knowledge-hub/content-editor/article-content/edit/help-center-modal': typeof HelpCenterModal;
  }
}
