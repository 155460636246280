/* import __COLOCATED_TEMPLATE__ from './save-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { TreeItem } from 'embercom/objects/tree-list';
import type Folder from 'embercom/models/content-service/folder';
import { addTreeItemToParent, createTreeItem } from '../tree-helper';
import { isEmpty } from '@ember/utils';
// @ts-ignore
import intermoji from '@intercom/intermoji';
//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import type RouterService from '@ember/routing/router-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

interface Args {
  item: TreeItem<Folder> | undefined;
  onClose: Function;
  position: string;
  updating: boolean;
}

export default class SaveModal extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: RouterService;

  @tracked name = '';
  @tracked folder: Folder | undefined;
  @tracked avatarEmoji: string | undefined;
  @tracked isSaving = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.updating && this.args.item instanceof TreeItem) {
      this.folder = this.args.item.dataObject;
      this.name = this.folder?.name || '';
      this.avatarEmoji = this.folder?.emoji;
    }
  }

  get app() {
    return this.appService.app;
  }

  get isValid() {
    return this.name.trim() !== '';
  }

  get updatedButtonLabelKey() {
    return this.intl.t('knowledge-hub.folders.folders-menu.save');
  }

  get titleKey() {
    if (this.args.updating) {
      return 'knowledge-hub.folders.folders-menu.update-modal.title';
    } else {
      return this.createAtRoot
        ? 'knowledge-hub.folders.folders-menu.create-modal.title'
        : 'knowledge-hub.folders.folders-menu.create-modal.subfolder.title';
    }
  }

  get createAtRoot() {
    return !this.args.updating && this.args.item?.parent.isRoot;
  }

  get createButtonLabelKey() {
    return this.createAtRoot
      ? 'knowledge-hub.folders.folders-menu.create-modal.button'
      : 'knowledge-hub.folders.folders-menu.create-modal.subfolder.button';
  }

  get placeholderKey() {
    return this.createAtRoot
      ? 'knowledge-hub.folders.folders-menu.create-modal.name-placeholder'
      : 'knowledge-hub.folders.folders-menu.create-modal.subfolder.name-placeholder';
  }

  get saveDisabled() {
    return !this.isValid || this.isSaveInProgress;
  }

  get isSaveInProgress() {
    return Boolean(this.folder?.isSaving) || this.isSaving;
  }

  set isSaveInProgress(_value) {
    // noop
  }

  @action
  async updateFolder() {
    if (!this.folder) {
      return;
    }

    this.isSaving = true;
    let hasNameChanged = this.folder.name !== this.name;
    let original_name = this.folder.name;
    try {
      this.folder.name = this.name;
      if (this.avatarEmoji) {
        this.folder.emoji = this.avatarEmoji;
      }
      await this.folder.save();

      if (hasNameChanged && this.folder.treeItem && this.folder.treeItem.parent) {
        this.folder.treeItem?.removeFromParent();
        addTreeItemToParent(this.folder.treeItem, this.folder.treeItem?.parent);
      }
      this.isSaving = false;

      this.notificationsService.notifyConfirmation(
        this.intl.t('knowledge-hub.folders.folders-menu.update-modal.success'),
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'updated',
        object: 'folder',
        position: this.args.position,
      });

      this.args.onClose();
    } catch (err) {
      if (hasNameChanged) {
        this.folder.name = original_name;
      }
      this.args.onClose();
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.folders.folders-menu.update-modal.error'),
      );
    }
  }

  @action
  async saveNewFolder() {
    if (!this.args.item) {
      this.args.onClose();
      return;
    }

    this.isSaving = true;
    let folder: Folder;

    let parentFolder = this.args.item.isRoot ? null : this.args.item.dataObject;

    try {
      folder = this.store.createRecord('content-service/folder', {
        name: this.name,
        emoji: this.avatarEmoji,
        description: null,
        isSynced: false,
        parentFolder,
      });

      await folder.save();

      let newTreeItem = createTreeItem(this.args.item, folder);

      addTreeItemToParent(newTreeItem, this.args.item);

      this.isSaving = false;
      this.notificationsService.notifyConfirmation(
        this.intl.t('knowledge-hub.folders.folders-menu.create-modal.success'),
      );

      if (folder.parentIsCurrentRoute) {
        this.knowledgeHubService.api?.refreshPageAfterDelay();
      }

      this.intercomEventService.trackAnalyticsEvent({
        action: 'created',
        object: 'folder',
        position: this.args.position,
      });

      this.args.onClose();
    } catch (err) {
      this.args.onClose();
    }
  }

  @action cancel() {
    this.args.onClose();
  }

  @action handleKeydown(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (isEmpty(this.name)) {
        return;
      }
      if (this.args.updating) {
        this.updateFolder();
      } else {
        this.saveNewFolder();
      }
    }
  }

  @action selectAvatarEmoji(emojiIdentifier: string) {
    let unicodeEmoji = intermoji.unicodeFromIdentifier(emojiIdentifier);
    let twemojiCompatibleEmoji = intermoji.getSupportedTwemoji(unicodeEmoji);
    this.avatarEmoji = twemojiCompatibleEmoji;
  }

  get htmlSafedisplayableSelectedEmoji() {
    return sanitizeHtml(this.avatarEmoji);
  }

  get defaultFolderIcon() {
    return this.folder?.isAiGenerated ? 'ai' : 'collection';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Folders::Modals::SaveModal': typeof SaveModal;
    'knowledge-hub/folders/modals/save-modal': typeof SaveModal;
  }
}
