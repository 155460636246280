/* import __COLOCATED_TEMPLATE__ from './view.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type Model from '@ember-data/model';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import type Folder from 'embercom/models/content-service/folder';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { type KnowledgeHubEditorConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import { EntityType } from 'embercom/models/data/entity-types';
import type ArticleContent from 'embercom/models/articles/article-content';
import type RouterService from '@ember/routing/router-service';
interface Args {
  activeContent: LocalizedKnowledgeContent & Model;
}

export default class View extends Component<Args> {
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare appService: any;
  @service declare permissionsService: any;
  @service declare helpCenterService: any;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;
  @tracked showSidePanel = true;

  get editorConfig(): KnowledgeHubEditorConfig | undefined {
    return this.args.activeContent && this.args.activeContent.editorConfig
      ? this.args.activeContent.editorConfig
      : undefined;
  }

  get paywallConfig() {
    return this.knowledgeHubEditorService.paywallConfig;
  }

  get getActiveContentParent(): Folder | undefined {
    return this.args.activeContent.parentContent.parent;
  }

  get shouldShowUnsupportedContentBanner() {
    if (!this.appService.app.canUseFeature('group-ps-handle-unsupported-elements')) {
      return false;
    }

    return !!this.args.activeContent.needsReview;
  }

  @action
  toggleSidePanel(): void {
    this.showSidePanel = !this.showSidePanel;
    this.knowledgeHubEditorService.trackAnalyticsEvent(
      `${this.showSidePanel ? 'show' : 'hide'}_details_side_panel`,
    );
  }

  @action navigateToHelpCenter(): void {
    this.knowledgeHubEditorService.closeSetHelpCenterLiveModal();
    let helpCenterId;

    if (this.args.activeContent.entityType === EntityType.ArticleContent) {
      let helpCenterIds = (this.args.activeContent as unknown as ArticleContent).article
        .helpCenterIds;
      helpCenterId = helpCenterIds.firstObject;
    }

    // if unlisted, go to default help center
    if (!helpCenterId) {
      helpCenterId = this.helpCenterService.defaultSite.id;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'after_publish',
      location: 'set_help_center_live_modal',
      object: `go_to_help_center_button`,
    });

    this.router.transitionTo(
      'apps.app.settings.helpcenter.workspace-helpcenter.collections',
      this.appService.app.id,
      helpCenterId,
    );
  }

  @action async editActiveContent() {
    try {
      await this.permissionsService.checkPermission(
        this.args.activeContent.requiredEditPermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }
    this.knowledgeHubEditorService.editActiveContent();
  }

  @dropTask
  *unpublishActiveContent(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission(
        this.args.activeContent.requiredPublishPermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }

    yield this.knowledgeHubEditorService.unpublishActiveContent();
  }

  @dropTask
  *publishActiveContent(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission(
        this.args.activeContent.requiredPublishPermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }

    yield this.knowledgeHubEditorService.publishActiveContent();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::View': typeof View;
    'knowledge-hub/content-editor/view': typeof View;
  }
}
