/* import __COLOCATED_TEMPLATE__ from './rotating-chevron.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import templateOnlyComponent from '@ember/component/template-only';
import type PALETTE from '@intercom/pulse/lib/palette';
export interface Signature {
  Element: HTMLElement;
  Args: {
    expanded: boolean;
    color?: keyof typeof PALETTE;
  };
}

const ReadOnly = templateOnlyComponent<Signature>();
export default ReadOnly;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::RotatingChevron': typeof ReadOnly;
  }
}
