/* import __COLOCATED_TEMPLATE__ from './all-sources-banner.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import type IntlService from 'ember-intl/services/intl';
import type Router from '@ember/routing/router-service';
import { ContentFilter } from './overview';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';

export type SourceCardConfig = {
  source: ContentFilter;
  title: string;
  description: string;
  ctaLabel: string;
  ctaAction: () => void;
  isLive: boolean;
  imageUrl: string;
};

interface Signature {
  Element: HTMLDivElement;
  Args: {
    styleForContent?: boolean;
    dismissalKey: string;
  };
}

export default class AllSourcesBanner extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare helpCenterService: $TSFixMe;

  get copilotEnabled() {
    return true;
  }

  get hasSingleSite() {
    return this.helpCenterService.sites?.length === 1 && !!this.helpCenterService.site;
  }

  get hasLiveHelpCenter() {
    return this.helpCenterService.allLiveSites.length > 0;
  }

  get aiAgentEnabled() {
    return this.aiAgentSetupService.isFinLive;
  }

  @action
  private trackAnalyticsEvent(section: ContentFilter) {
    let page = this.args.styleForContent ? 'content' : 'overview';
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'discovery_banner',
      place: `knowledge_hub_${page}_all`, // which tab was clicked
      section: section.replaceAll('-', '_'),
    });
  }

  get sourceCardConfigs(): SourceCardConfig[] {
    let SET_UP_NOW = this.intl.t('knowledge-hub.overview.button-labels.set-up-now');
    let GO_TO_INBOX = this.intl.t('knowledge-hub.overview.button-labels.go-to-inbox');
    let GO_TO_SETTINGS = this.intl.t('knowledge-hub.overview.button-labels.go-to-settings');
    let GO_TO_AI_AND_AUTOMATION = this.intl.t('knowledge-hub.overview.button-labels.go-to-ai-page');

    return [
      {
        source: ContentFilter.AiAgent,
        title: this.intl.t('knowledge-hub.overview.source-card.title.ai-agent'),
        description: this.intl.t('knowledge-hub.overview.source-card.description.ai-agent'),
        ctaAction: () => {
          this.trackAnalyticsEvent(ContentFilter.AiAgent);
          this.router.transitionTo('apps.app.fin-ai-agent.setup');
        },
        isLive: this.aiAgentEnabled,
        ctaLabel: this.aiAgentEnabled ? GO_TO_AI_AND_AUTOMATION : SET_UP_NOW,
        imageUrl: assetUrl('/assets/images/knowledge-hub/overview/source-cards/ai-agent.png'),
      },
      {
        source: ContentFilter.AiCopilot,
        title: this.intl.t('knowledge-hub.overview.source-card.title.ai-copilot'),
        description: this.intl.t('knowledge-hub.overview.source-card.description.ai-copilot'),
        isLive: this.copilotEnabled,
        ctaLabel: GO_TO_INBOX,
        imageUrl: assetUrl('/assets/images/knowledge-hub/overview/source-cards/ai-copilot.png'),
        ctaAction: () => {
          this.trackAnalyticsEvent(ContentFilter.AiCopilot);
          this.router.transitionTo(
            'inbox.workspace.inbox.inbox',
            this.appService.app.id,
            InboxCategory.Shared,
            InboxType.All,
            {
              queryParams: {
                open_copilot: true,
              },
            },
          );
        },
      },
      {
        source: ContentFilter.HelpCenter,
        title: this.intl.t('knowledge-hub.overview.source-card.title.help-center'),
        description: this.intl.t('knowledge-hub.overview.source-card.description.help-center'),
        ctaAction: () => {
          this.trackAnalyticsEvent(ContentFilter.HelpCenter);
          this.hasSingleSite
            ? this.router.transitionTo(
                'apps.app.settings.helpcenter.workspace-helpcenter.collections',
                this.appService.app,
                this.helpCenterService.site,
              )
            : this.router.transitionTo('apps.app.settings.helpcenter.all');
        },
        isLive: this.hasLiveHelpCenter,
        ctaLabel: this.hasLiveHelpCenter ? GO_TO_SETTINGS : SET_UP_NOW,
        imageUrl: assetUrl('/assets/images/knowledge-hub/overview/source-cards/help-center.png'),
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::AllSourcesBanner': typeof AllSourcesBanner;
    'knowledge-hub/all-sources-banner': typeof AllSourcesBanner;
  }
}
