/* import __COLOCATED_TEMPLATE__ from './state-indicator.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { type AiContentState } from 'embercom/lib/ai-content-library/constants';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type KnowledgeHubContentStatus } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  states: AiContentState[] | KnowledgeHubContentStatus[];
  stateWhenUsed: AiContentState | KnowledgeHubContentStatus;
  preCondition: boolean;
}

interface IconAndColor {
  icon: InterfaceIconName;
  color: 'success-fill' | 'text-muted';
}

export default class StateIndicator extends Component<Args> {
  @service declare intl: IntlService;

  get hasMixedStates(): boolean {
    return this.args.states.length > 1;
  }

  get iconAndColor(): IconAndColor {
    if (this.args.states.length !== 1) {
      return {
        icon: 'lead',
        color: 'text-muted',
      };
    } else if (this.args.states.firstObject === this.args.stateWhenUsed && this.args.preCondition) {
      return {
        icon: 'check',
        color: 'success-fill',
      };
    } else {
      return {
        icon: 'x',
        color: 'text-muted',
      };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::StateIndicator': typeof StateIndicator;
    'knowledge-hub/filterable-list/table/cells/State-indicator': typeof StateIndicator;
  }
}
