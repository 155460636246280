/* import __COLOCATED_TEMPLATE__ from './conversation-extraction.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Component from '@glimmer/component';
import { type OverviewSectionArgs } from '../../overview';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type SourceRowActionOption, type SourceRow } from './section';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export default class ConversationExtraction extends Component<OverviewSectionArgs> {
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare conversationExtractionService: ConversationExtractionService;
  @tracked showConversationSettingsModal = false;
  @tracked showDisableFeatureModal = false;

  get conversationExtractionSettings() {
    return this.conversationExtractionService.conversationExtractionSettings;
  }

  get isLatestImportInProgress() {
    return this.conversationExtractionSettings?.isLatestImportInProgress || false;
  }

  get status() {
    if (this.isLatestImportInProgress) {
      return this.intl.t(
        'knowledge-hub.overview.sections.conversation-extraction.rows.in-progress.description',
      );
    }
    if (this.conversationExtractionSettings?.enabled) {
      return this.intl.t(
        'knowledge-hub.overview.sections.conversation-extraction.rows.active.description',
      );
    }
    return this.intl.t(
      'knowledge-hub.overview.sections.conversation-extraction.rows.empty.description',
    );
  }

  get statusIcon(): any {
    if (this.isLatestImportInProgress) {
      return 'sync';
    }
    if (this.conversationExtractionSettings?.enabled) {
      return 'check';
    }
    return undefined;
  }

  get actionButtonLabel() {
    if (this.conversationExtractionSettings?.enabled) {
      return this.intl.t(
        'knowledge-hub.overview.sections.conversation-extraction.rows.active.action.label',
      );
    }
    return this.intl.t(
      'knowledge-hub.overview.sections.conversation-extraction.rows.empty.action.label',
    );
  }

  get actionOptions() {
    if (!this.conversationExtractionSettings?.enabled) {
      return undefined;
    }

    let disableOpenSettings =
      this.conversationExtractionSettings?.isLatestImportInProgress ?? false;

    let dropdownItems: SourceRowActionOption[] = [
      {
        text: this.intl.t(
          'operator.fin.setup.content-sources.conversation-content.conversation-extraction-experiment.manage-dropdown-options.open-settings',
        ),
        icon: 'settings',
        isDestructive: false,
        onSelectItem: this.openConversationSettingsModal,
        isDisabled: disableOpenSettings,
        tooltipText: disableOpenSettings
          ? this.intl.t(
              'knowledge-hub.conversational.conversation-extraction-settings.tooltip.settings-blocked',
            )
          : undefined,
      },
      {
        text: this.intl.t(
          'operator.fin.setup.content-sources.conversation-content.conversation-extraction-experiment.manage-dropdown-options.go-to-content',
        ),
        icon: 'new-window',
        isDestructive: false,
        onSelectItem: async () => {
          await this.conversationExtractionService.goToContent(false);
        },
      },
      {
        text: this.intl.t(
          'operator.fin.setup.content-sources.conversation-content.conversation-extraction-experiment.manage-dropdown-options.give-feedback',
        ),
        icon: 'survey-filled',
        isDestructive: false,
        onSelectItem: this.conversationExtractionService.openFeedbackSurvey,
      },
      {
        text: this.intl.t(
          'operator.fin.setup.content-sources.conversation-content.conversation-extraction-experiment.manage-dropdown-options.disable-feature',
        ),
        icon: 'trash',
        isDestructive: true,
        onSelectItem: this.openDisableFeatureModal,
      },
    ];

    return [{ items: dropdownItems }];
  }

  get rows(): SourceRow[] {
    return [
      {
        icon: 'intercom' as InterfaceIconName,
        statusIcon: this.statusIcon,
        title: this.intl.t(
          'knowledge-hub.overview.sections.conversation-extraction.rows.active.title',
        ),
        onTitleClick: async () => {
          await this.conversationExtractionService.goToContent(false);
        },
        status: this.status,
        onActionButtonClick: this.openConversationSettingsModal,
        actionButtonLabel: this.actionButtonLabel,
        actionOptions: this.actionOptions,
      },
    ];
  }

  @action async disableFeature() {
    await this.conversationExtractionService.disableFeature(() => {
      this.showDisableFeatureModal = false;
    });
  }

  @action openConversationSettingsModal() {
    this.showConversationSettingsModal = true;
  }

  @action openDisableFeatureModal() {
    this.showDisableFeatureModal = true;
  }

  @action
  onModalClose() {
    this.showConversationSettingsModal = false;
    this.conversationExtractionService.maybeRollbackConversationExtractionSettings();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::ConversationExtraction': typeof ConversationExtraction;
  }
}
