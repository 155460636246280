/* import __COLOCATED_TEMPLATE__ from './drawer-controls.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';

export default class DrawerControls extends Component {
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;

  @action
  async closeDrawer() {
    await this.knowledgeHubDrawerEditorService.closeDrawer();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Header::DrawerControls': typeof DrawerControls;
    'knowledge-hub/content-editor/shared/header/drawer-controls': typeof DrawerControls;
  }
}
