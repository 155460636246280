/* import __COLOCATED_TEMPLATE__ from './collection-picker-modal-contents.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { COLLECTION_PICKER_MODAL_OPTIONS } from './collection-picker-modal-options';
import ArticleGroup from 'embercom/models/articles/article-group';
import type Store from '@ember-data/store';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type RouterService from '@ember/routing/router-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { EntityType } from 'embercom/models/data/entity-types';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    modal: any;
    title?: string;
    onNavigationBack?: () => void;
    onNavigationNext?: (viewId?: string) => void;
    showBackButton?: boolean;
    onModalClose: () => void;
    context: {
      onClickStartFromScratch?: () => void;
      helpCenterId?: string;
      addRootCollection?: (collectionId: string) => void;
      refreshData?: () => void;
      eventSource?: string;
      folder?: string;
    };
  };
}

export default class CollectionPickerModalContents extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare intercomEventService: any;
  @service declare router: RouterService;
  @service declare helpCenterService: $TSFixMe;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: $TSFixMe;

  @tracked selectedOptionIds: string[] = [];

  @action
  onClickStartFromScratch() {
    if (this.args.context?.onClickStartFromScratch) {
      this.args.context.onClickStartFromScratch();
    }
    this.args.onNavigationNext?.('collection-editor');
  }

  @action
  optionPicked(optionId: string) {
    if (this.selectedOptionIds.includes(optionId)) {
      this.selectedOptionIds = this.selectedOptionIds.filter((option) => option !== optionId);
    } else {
      this.selectedOptionIds = [...this.selectedOptionIds, optionId];
    }
  }

  @action getOptionStyles(optionId: string) {
    if (this.selectedOptionIds.includes(optionId)) {
      return {
        bgColor: 'bg-beta-fill',
        textColor: 'text-on-beta',
        textColorOnHover: 'text-on-beta',
      };
    } else {
      return {
        bgColor: 'bg-neutral-container',
        textColor: 'text-default',
        textColorOnHover: 'text-link',
      };
    }
  }

  @action
  async createCollections() {
    await taskFor(this.createCollectionsTask).perform();
    this.args.context.refreshData?.();
    this.args.onModalClose();

    if (
      this.appService.app.setUpYourHcFirstModalFlow &&
      // use 'startsWith' because the route can be the Sources page or the Contents page
      this.router.currentRouteName?.startsWith('apps.app.knowledge-hub')
    ) {
      this.knowledgeHubService.createContent(EntityType.ArticleContent, {
        folder_id: this.args.context.folder,
      });
      this.args.onModalClose();
    }
  }

  @task({ drop: true })
  *createCollectionsTask(): TaskGenerator<void> {
    let selectedOptions = COLLECTION_PICKER_MODAL_OPTIONS.filter((option) =>
      this.selectedOptionIds.includes(option.id),
    );

    let promises = selectedOptions.map(async (option) => {
      let collection = ArticleGroup.createNewFolder(
        this.store.peekRecord('articles/article-group', 'home'),
        undefined,
        this.args.context.helpCenterId,
      );
      collection.name = option.label;
      collection.description = option.description;
      collection.icon = option.icon;
      await collection.save();
    });

    yield Promise.all(promises);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'create_collections',
      object: 'collection',
      place: 'collection_picker_modal',
      section: 'help_center_settings',
      count: this.selectedOptionIds.length,
      values: this.selectedOptionIds,
    });
  }

  get collectionPickerModalOptions() {
    return COLLECTION_PICKER_MODAL_OPTIONS;
  }

  get createCollectionsButtonDisabled() {
    return this.selectedOptionIds.length === 0 || taskFor(this.createCollectionsTask).isRunning;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::CollectionPickerModalContents': typeof CollectionPickerModalContents;
    'knowledge-hub/content-editor/shared/collection-picker-modal-contents': typeof CollectionPickerModalContents;
  }
}
