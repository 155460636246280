/* import __COLOCATED_TEMPLATE__ from './content-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';
import {
  ArticlePublishedStatuses,
  CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
} from 'embercom/lib/knowledge-hub/constants';
import { type KnowledgeHubApiQueryParams } from 'embercom/lib/knowledge-hub/list-api';
import { EntityType } from 'embercom/models/data/entity-types';
import { objectIcons } from 'embercom/models/data/matching-system/matching-constants';
import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import moment from 'moment-timezone';
import { type ContentFilter, type KnowledgeUsageSummary, type SyncSourceType } from '../types';
import ConnectSourceDropdownOption from '../shared/connect-source-dropdown-option';
import {
  type SourceRow,
  type SourceRowActionOption,
  type SourceRowActionsGroupList,
} from './section';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import { taskFor } from 'ember-concurrency-ts';
import { SyncBehavior } from 'embercom/models/knowledge-hub/sync-source-wrapper';
import type ArticleGroup from 'embercom/models/articles/article-group';

export interface SyncSettings {
  showTypeInTitle?: boolean;
}

export interface NotSetupSyncSettings {
  actionLabel?: string;
  notSetupStatus?: string;
}

export interface AllContentSettings {
  minContentTreshold?: number;
  statusTooltip?: string;
  onTitleClick?: () => void;
  onActionButtonClick?: () => void;
  descriptionCount?: number;
  isDisabled?: boolean;
}

export interface ContentSectionArgs {
  knowledgeUsageSummary: KnowledgeUsageSummary;
  entityType: EntityType;
  showAllContent?: boolean;
  contentFilter: ContentFilter;
  sectionButtonLabel?: string;
  onSectionButtonClick?: () => void;
  onAddSyncSource: (sourceType: SyncSourceType) => void;
  activeSync?: SyncSettings;
  notSetupSync?: NotSetupSyncSettings;
  allContent?: AllContentSettings;
  showNotSetupSync?: boolean;
  maxRows?: number;
}

const ENTITY_TYPE_TO_SOURCE_TYPES: Partial<Record<EntityType, SyncSourceType[]>> = {
  [EntityType.ArticleContent]: ['zendesk'],
  [EntityType.InternalArticle]: ['guru', 'notion', 'confluence'],
  [EntityType.ExternalContent]: ['external_content'],
  [EntityType.ConversationExcerpt]: ['zendesk_tickets'],
};

const PAYWALLS: Partial<Record<EntityType, { paywallFeatureKey: string }>> = {};

export default class ContentSection extends Component<ContentSectionArgs> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare permissionsService: any;
  @service declare intercomEventService: any;
  @service declare router: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare notificationsService: any;
  @service declare store: Store;

  @tracked showRemoveSourceModal = false;
  @tracked sourceToRemove?: SyncSourceWrapper | null;

  @tracked showSourceSettingsModal = false;
  @tracked sourceToEdit?: ContentImportSource | null;
  @tracked sourceEditWrapper?: SyncSourceWrapper | null;
  @tracked showModalToSetupHelpCenter = false;

  get rows(): SourceRow[] {
    return [
      ...(this.args.showAllContent ? [this.allContentRow()] : []),
      ...this.syncRowsForEntity(this.args.entityType),
    ];
  }

  get iconName() {
    return objectIcons[this.args.entityType];
  }

  syncRowsForEntity(entityType: EntityType): SourceRow[] {
    let sourceTypes = ENTITY_TYPE_TO_SOURCE_TYPES[entityType];
    if (!sourceTypes) {
      return [];
    }
    return sourceTypes.flatMap((sourceType) => this.syncRows(sourceType));
  }

  get contentSummary() {
    return this.args.knowledgeUsageSummary[this.args.entityType];
  }

  syncSources(sourceType: SyncSourceType) {
    let sources = this.store.peekAll('knowledge-hub/sync-source-wrapper');
    return sources.filter((source) => source.sourceType === sourceType);
  }

  syncRows(sourceType: SyncSourceType): SourceRow[] {
    let sources = this.syncSources(sourceType);
    if (sources.length === 0) {
      if (this.args.showNotSetupSync) {
        return [this.emptySyncRow(sourceType)];
      }
      return [];
    }
    return sources.map((source) => this.activeSyncRow(source));
  }

  showModalToSetupHelpCenterIfNoCollections = async () => {
    let articleGroups = await this.store.findAll('articles/article-group');
    let noCollections =
      articleGroups.toArray().filter((el: ArticleGroup) => el.id !== 'home').length === 0;
    if (noCollections) {
      this.showModalToSetupHelpCenter = true;
    } else {
      this.knowledgeHubService.createContent(this.args.entityType);
    }
  };

  allContentRow(): SourceRow {
    let createContentFn;
    if (
      this.appService.app.setUpYourHcFirstModalFlow &&
      this.args.entityType === EntityType.ArticleContent
    ) {
      createContentFn = this.showModalToSetupHelpCenterIfNoCollections;
    } else {
      createContentFn = () => this.knowledgeHubService.createContent(this.args.entityType);
    }

    let onActionButtonClick = this.args.allContent?.onActionButtonClick || createContentFn;
    let goToContentFn = () =>
      this.knowledgeHubService.goToContent(this.args.entityType, this.contentTableFilterParams);
    let onTitleClick = this.args.allContent?.onTitleClick || goToContentFn;
    return {
      statusIcon: this.hasEnoughContent && !this.args.allContent?.isDisabled ? 'check' : undefined,
      iconUrl: assetUrl('/assets/images/knowledge-hub/icons/intercom.png'),
      title: this.intl.t(
        `knowledge-hub.overview.sections.${this.args.entityType}.rows.active.title`,
      ),
      onTitleClick,
      status: this.allContentRowDescription,
      statusTooltip:
        (!this.args.allContent?.isDisabled ?? true) && !this.hasEnoughContent
          ? this.args.allContent?.statusTooltip
          : undefined,
      onActionButtonClick,
      actionButtonLabel: this.intl.t(
        `knowledge-hub.overview.sections.${this.args.entityType}.rows.active.action.label`,
      ),
      paywallFeatureKey: PAYWALLS[this.args.entityType]?.paywallFeatureKey,
    };
  }

  get contentTableFilterParams(): KnowledgeHubApiQueryParams {
    let filter: KnowledgeHubApiQueryParams = {};

    if (this.args.contentFilter === 'help-center') {
      filter.status = ArticlePublishedStatuses.PUBLISHED;
    }

    if (this.args.contentFilter === 'ai-agent') {
      filter.chatbotState = AiContentState.USED_BY_FIN;
    } else if (this.args.contentFilter === 'ai-copilot') {
      filter.copilotState = AiContentState.USED_BY_FIN;
    }
    return filter;
  }

  get hasEnoughContent() {
    let minContentTreshold = this.args.allContent?.minContentTreshold || 1;
    return this.contentCount >= minContentTreshold;
  }

  get allContentRowDescription() {
    if (this.args.allContent?.isDisabled === true) {
      return this.intl.t(
        `knowledge-hub.overview.sections.${this.args.entityType}.rows.disabled.description`,
      );
    }
    if (!this.hasEnoughContent) {
      return this.intl.t(
        `knowledge-hub.overview.sections.${this.args.entityType}.rows.empty.${this.args.contentFilter}.description`,
      );
    }
    return this.intl.t(this.descriptionTranslationKey, {
      count: this.args.allContent?.descriptionCount
        ? this.args.allContent.descriptionCount
        : this.contentCount,
      contentFilter: this.intl.t(
        `knowledge-hub.overview.sections.content-filter.${this.args.contentFilter}`,
      ),
    });
  }

  get descriptionTranslationKey() {
    return this.args.contentFilter === 'help-center' &&
      this.args.entityType === EntityType.ArticleContent
      ? `knowledge-hub.overview.sections.${this.args.entityType}.rows.active.description-published`
      : `knowledge-hub.overview.sections.${this.args.entityType}.rows.active.description`;
  }

  private fetchErrorTooltip(type: 'resync' | 'settings', syncSource: SyncSourceWrapper) {
    if (syncSource.syncBehavior === SyncBehavior.API) {
      return this.intl.t(
        `knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-${type}-tooltip-api`,
      );
    }
    return this.intl.t(
      `knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-${type}-tooltip`,
    );
  }

  emptySyncRow(sourceType: SyncSourceType): SourceRow {
    let actionButtonLabel = this.appService.app.canUseStandalone
      ? this.intl.t(`knowledge-hub.overview.sections.syncs.rows.empty.action.sync-label`)
      : this.args.notSetupSync?.actionLabel ||
        this.intl.t(`knowledge-hub.overview.sections.syncs.rows.empty.action.label`);
    return {
      iconUrl: assetUrl(`/assets/images/knowledge-hub/icons/${sourceType}.png`),
      title: this.intl.t(`knowledge-hub.overview.sections.syncs.types.${sourceType}`),
      status:
        this.args.notSetupSync?.notSetupStatus ||
        this.intl.t(`knowledge-hub.overview.sections.syncs.rows.empty.description`),
      onActionButtonClick: () => this.args.onAddSyncSource(sourceType),
      actionButtonLabel,
    };
  }

  activeSyncOptions(syncSource: SyncSourceWrapper): SourceRowActionsGroupList {
    let actions: SourceRowActionOption[] = [];
    if (syncSource.canResync) {
      actions.push({
        text: this.intl.t(
          'knowledge-hub.overview.sections.syncs.rows.active.action.options.resync',
        ),
        icon: 'sync',
        onSelectItem: syncSource.isSyncing ? () => {} : () => taskFor(syncSource.resync).perform(),
        isDestructive: false,
        isDisabled: syncSource.isSyncing || syncSource.syncBehavior === SyncBehavior.API,
        tooltipText: this.fetchErrorTooltip('resync', syncSource),
        tooltip: { isDelayed: false },
      });

      if (
        this.appService.app.canEditWebsyncsInKnowledgeHub &&
        syncSource.sourceType === 'external_content'
      ) {
        actions.push({
          text: this.intl.t(
            'knowledge-hub.overview.sections.syncs.rows.active.action.options.open-settings',
          ),
          icon: 'settings',
          onSelectItem: syncSource.isSyncing
            ? () => {}
            : () => this.requestSourceSettings(syncSource),
          isDestructive: false,
          isDisabled: syncSource.isSyncing || syncSource.syncBehavior === SyncBehavior.API,
          tooltipText: this.fetchErrorTooltip('settings', syncSource),
          tooltip: { isDelayed: false },
        });
      }
    }

    if (syncSource.canImport) {
      actions.push({
        text: this.intl.t(
          'knowledge-hub.overview.sections.syncs.rows.active.action.options.import',
        ),
        icon: 'cloud-upload',
        onSelectItem: syncSource.isSyncing ? () => {} : () => syncSource.import(),
        isDestructive: false,
        isDisabled: syncSource.isSyncing,
        tooltipText: syncSource.isSyncing
          ? this.intl.t(
              'knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-import-tooltip',
            )
          : undefined,
        tooltip: { isDelayed: false },
      });
    }

    if (syncSource.canRemove) {
      actions.push({
        text: this.intl.t(
          'knowledge-hub.overview.sections.syncs.rows.active.action.options.remove',
        ),
        icon: 'trash',
        onSelectItem: syncSource.isSyncing ? () => {} : () => this.requestRemoveSource(syncSource),
        isDestructive: true,
        isDisabled: syncSource.isSyncing,
        tooltipText: syncSource.isSyncing
          ? this.intl.t(
              'knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-remove-tooltip',
            )
          : undefined,
        tooltip: { isDelayed: false },
      });
    }

    let options = [{ items: actions }];
    if (syncSource.canAddNew) {
      options.push({
        items: [
          {
            text: this.intl.t(
              'knowledge-hub.overview.sections.syncs.rows.active.action.options.connect',
              {
                type: this.intl.t(
                  `knowledge-hub.overview.sections.syncs.types.${syncSource.sourceType}`,
                ),
              },
            ),
            icon: 'new',
            onSelectItem: () => this.args.onAddSyncSource(syncSource.sourceType),
            isDestructive: false,
            component: ConnectSourceDropdownOption,
          },
        ],
      });
    }
    return options;
  }

  activeSyncRow(syncSource: SyncSourceWrapper): SourceRow {
    let { sourceType } = syncSource;
    let onTitleClick;
    if (syncSource.folderId) {
      onTitleClick = () =>
        this.knowledgeHubService.goToSyncSourceFolder(syncSource, this.contentTableFilterParams);
    }
    let row: SourceRow = {
      title: this.syncTitle(syncSource),
      onTitleClick,
      folderId: syncSource.folderId,
      status: this.syncStatus(syncSource),
      statusIcon: this.syncStatusIcon(syncSource),
      actionButtonLabel: this.intl.t(
        `knowledge-hub.overview.sections.syncs.rows.active.action.label`,
      ),
      actionOptions: this.activeSyncOptions(syncSource),
    };

    if (sourceType === 'external_content') {
      return { ...row, icon: 'globe' };
    }

    return { ...row, iconUrl: assetUrl(`/assets/images/knowledge-hub/icons/${sourceType}.png`) };
  }

  syncTitle(syncSource: SyncSourceWrapper) {
    let { sourceType } = syncSource;
    if (!this.args.activeSync?.showTypeInTitle) {
      return syncSource.title || '';
    }
    return `${this.intl.t(
      `knowledge-hub.overview.sections.syncs.types.${sourceType}`,
    )} ${syncSource.title ? `(${syncSource.title})` : ''}`;
  }

  syncStatusIcon(syncSource: SyncSourceWrapper): InterfaceIconName {
    let { status } = syncSource;
    switch (status) {
      case 'live':
        return 'check';
      case 'error':
        return 'warning';
      case 'syncing':
        return 'sync';
      default:
        return 'check';
    }
  }

  syncStatus(syncSource: SyncSourceWrapper) {
    if (syncSource.hasError && syncSource.errorMessage) {
      return syncSource.errorMessage;
    } else if (syncSource.isSyncing) {
      let statusKey = syncSource.isImport ? 'importing' : 'syncing';
      return this.intl.t(
        `knowledge-hub.overview.sections.syncs.rows.active.description.${statusKey}`,
      );
    }
    return this.intl.t('knowledge-hub.overview.sections.syncs.rows.active.last-synced', {
      date: moment(syncSource.lastSyncedAt).fromNow(),
    });
  }

  get contentCount() {
    let { agent, copilot, all, help_center } = this.contentSummary;
    switch (this.args.contentFilter) {
      case 'all':
        return all || 0;
      case 'ai-agent':
        return agent || 0;
      case 'ai-copilot':
        return copilot || 0;
      case 'help-center':
        return help_center || 0;
      default:
        return 0;
    }
  }

  @action async requestRemoveSource(syncSource: SyncSourceWrapper) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (error) {
      return;
    }

    this.sourceToRemove = syncSource;
    this.setShowRemoveSourceModal(true);
  }

  setShowRemoveSourceModal(state = false) {
    this.showRemoveSourceModal = state;
  }

  @action async removeSource() {
    let sourceToRemove = this.sourceToRemove;
    if (!sourceToRemove) {
      return;
    }

    await sourceToRemove.remove();
    sourceToRemove = null;
    this.closeRemoveModal();
  }

  @action closeRemoveModal() {
    this.setShowRemoveSourceModal(false);
    this.sourceToRemove = undefined;
  }

  @action async requestSourceSettings(syncSource: SyncSourceWrapper) {
    this.sourceToEdit = await syncSource.show();
    this.sourceEditWrapper = syncSource;
    this.setShowSourceSettingsModal(true);
  }

  setShowSourceSettingsModal(state = false) {
    this.showSourceSettingsModal = state;
  }

  @action closeSourceSettingsModal() {
    this.setShowSourceSettingsModal(false);
    this.sourceToEdit = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::ContentSection': typeof ContentSection;
    'knowledge-hub/overview/sections/content-section': typeof ContentSection;
  }
}
