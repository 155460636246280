/* import __COLOCATED_TEMPLATE__ from './discovery-banner.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { ContentFilter } from '../overview';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import type RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {
  contentFilter: ContentFilter;
  hasLiveHelpCenter?: boolean;
  helpCenterSettingsUrl?: string;
}

const BANNER_IMAGE_URLS = {
  [ContentFilter.All]: assetUrl('/assets/images/knowledge-hub/overview/all.png'),
  [ContentFilter.AiAgent]: assetUrl('/assets/images/knowledge-hub/overview/ai-agent.png'),
  [ContentFilter.AiCopilot]: assetUrl('/assets/images/knowledge-hub/overview/ai-copilot.png'),
  [ContentFilter.HelpCenter]: assetUrl('/assets/images/knowledge-hub/overview/help-center.png'),
};

const TITLE_KEYS = {
  [ContentFilter.All]: 'knowledge-hub.overview.discovery-banner.heading.all',
  [ContentFilter.AiAgent]: 'knowledge-hub.overview.new-discovery-banner.heading.ai-agent',
  [ContentFilter.AiCopilot]: 'knowledge-hub.overview.new-discovery-banner.heading.ai-copilot',
  [ContentFilter.HelpCenter]: 'knowledge-hub.overview.new-discovery-banner.heading.help-center',
};
const DESCRIPTION_KEYS = {
  [ContentFilter.All]: 'knowledge-hub.overview.discovery-banner.description.all',
  [ContentFilter.AiAgent]: 'knowledge-hub.overview.new-discovery-banner.description.ai-agent',
  [ContentFilter.AiCopilot]: 'knowledge-hub.overview.new-discovery-banner.description.ai-copilot',
  [ContentFilter.HelpCenter]: 'knowledge-hub.overview.new-discovery-banner.description.help-center',
};

export type BannerConfig = {
  imageUrl: string;
  description: string;
  title: string;
  isLive: boolean;
  ctaAction: () => void;
  ctaLabel: string;
  articleId: number;
};

export default class DiscoveryBanner extends Component<Args> {
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare finStandaloneService: FinStandaloneService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.aiAgentSetupService.load();
  }

  get bannerConfig(): BannerConfig {
    let SET_UP_NOW = 'knowledge-hub.overview.button-labels.set-up-now';
    let GO_TO_INBOX = 'knowledge-hub.overview.button-labels.go-to-inbox';
    let GO_TO_SETTINGS = 'knowledge-hub.overview.button-labels.go-to-settings';
    let GO_TO_FOR_AI_AGENT = this.appService.app.canUseStandalone
      ? 'knowledge-hub.overview.button-labels.go-to-channels'
      : 'knowledge-hub.overview.button-labels.go-to-ai-page';

    let config = {
      imageUrl: BANNER_IMAGE_URLS[this.args.contentFilter],
      title: TITLE_KEYS[this.args.contentFilter],
      description: DESCRIPTION_KEYS[this.args.contentFilter],
    };
    if (this.args.contentFilter === ContentFilter.AiAgent) {
      return {
        ...config,
        isLive: this.aiAgentEnabled,
        ctaAction: this.aiAgentCtaAction,
        ctaLabel: this.aiAgentEnabled ? GO_TO_FOR_AI_AGENT : SET_UP_NOW,
        articleId: 7120684,
      };
    } else if (this.args.contentFilter === ContentFilter.AiCopilot) {
      return {
        ...config,
        isLive: true,
        ctaAction: this.aiCopilotCtaAction,
        ctaLabel: GO_TO_INBOX,
        articleId: 9121374,
      };
    } else {
      return {
        ...config,
        isLive: this.helpCenterService.allLiveSites.length > 0,
        ctaAction: this.helpCenterCtaAction,
        ctaLabel: this.args.hasLiveHelpCenter ? GO_TO_SETTINGS : SET_UP_NOW,
        articleId: 56640,
      };
    }
  }

  get hasSingleSite() {
    return this.helpCenterService.sites?.length === 1 && !!this.helpCenterService.site;
  }

  get aiAgentEnabled() {
    if (this.appService.app.canUseStandalone) {
      return (
        this.finStandaloneService.zendeskConfig?.isEnabledForTickets ||
        this.finStandaloneService.zendeskConfig?.isEnabledForSunshine ||
        this.finStandaloneService.salesforceConfig?.isEnabled
      );
    }

    return this.aiAgentSetupService.isFinLive;
  }

  @action
  helpCenterCtaAction() {
    this.hasSingleSite
      ? this.router.transitionTo(
          'apps.app.settings.helpcenter.workspace-helpcenter.collections',
          this.appService.app,
          this.helpCenterService.site,
        )
      : this.router.transitionTo('apps.app.settings.helpcenter.all');
  }

  @action
  aiAgentCtaAction() {
    if (this.appService.app.canUseStandalone) {
      this.router.transitionTo('apps.app.standalone.channels.overview');
    } else {
      this.router.transitionTo('apps.app.fin-ai-agent.setup');
    }
  }

  @action
  aiCopilotCtaAction() {
    this.router.transitionTo(
      'inbox.workspace.inbox.inbox',
      this.appService.app.id,
      InboxCategory.Shared,
      InboxType.All,
      {
        queryParams: {
          open_copilot: true,
        },
      },
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::DiscoveryBanner': typeof DiscoveryBanner;
    'knowledge-hub/overview/discovery-banner': typeof DiscoveryBanner;
  }
}
