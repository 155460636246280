/* import __COLOCATED_TEMPLATE__ from './stamp.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  Element: HTMLElement;
  Args: {
    text: string;
    color: string;
    textStyle?: string;
    icon?: InterfaceIconName;
    noBorder?: boolean;
    notMuted?: boolean;
    tooltipKey?: string;
  };
}

interface StampColor {
  background: string;
  border: string;
  text: string;
}

const STAMP_COLOR_MAP = new Map<string, StampColor>([
  [
    'blue',
    {
      background: 'bg-neutral-container',
      border: 'border-neutral-border',
      text: 'text-default',
    },
  ],
  [
    'sky',
    {
      background: 'bg-beta-container',
      border: 'border-beta-border',
      text: 'text-default',
    },
  ],
  [
    'transparent',
    {
      background: 'bg-transparent',
      border: 'border-neutral-border',
      text: 'text-muted',
    },
  ],
  [
    'green',
    {
      background: 'bg-success-container',
      border: 'border-success-border',
      text: 'text-default',
    },
  ],
  [
    'gray',
    {
      background: 'bg-neutral-container',
      border: 'border-neutral-border',
      text: 'text-muted',
    },
  ],
  [
    'yellow-light',
    {
      background: 'bg-notice-container',
      border: 'border-notice-border',
      text: 'text-default',
    },
  ],
  [
    'yellow',
    {
      background: 'bg-notice-container',
      border: 'border-notice-border',
      text: 'text-default',
    },
  ],
  [
    'red',
    {
      background: 'bg-error-container',
      border: 'border-error-border',
      text: 'text-default',
    },
  ],
  [
    'paywall',
    {
      background: 'bg-paywall-container',
      border: 'border-paywall-border',
      text: 'text-default',
    },
  ],
]);

const DEFAULT_OPTION_CLASS = STAMP_COLOR_MAP.get('sky');
export default class Stamp extends Component<Args> {
  get color() {
    return this.args.color ? STAMP_COLOR_MAP.get(this.args.color) : DEFAULT_OPTION_CLASS;
  }

  get textStyle() {
    if (isPresent(this.args.textStyle)) {
      return this.args.textStyle;
    }
    return this.color ? this.color.text : '';
  }

  get tooltipIsEnabled() {
    return isPresent(this.args.tooltipKey);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Stamp': typeof Stamp;
  }
}
