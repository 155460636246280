/* import __COLOCATED_TEMPLATE__ from './set-up-help-center-first-modal.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { EntityType } from 'embercom/models/data/entity-types';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    modal: any;
    title?: string;
    onNavigationBack?: () => void;
    onNavigationNext?: (viewId?: string) => void;
    showBackButton?: boolean;
    onModalClose: () => void;
    context?: any;
  };
}

export default class SetUpHelpCenterFirstModal extends Component<Signature> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @action
  onClickNotNow() {
    this.knowledgeHubService.createContent(EntityType.ArticleContent, {
      folder_id: this.args.context?.folder,
    });
  }

  @action
  onClickGetStarted() {
    this.args.onNavigationNext?.('collection-picker');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::SetUpHelpCenterFirstModal': typeof SetUpHelpCenterFirstModal;
    'knowledge-hub/content-editor/shared/set-up-help-center-first-modal': typeof SetUpHelpCenterFirstModal;
  }
}
