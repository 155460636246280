/* import __COLOCATED_TEMPLATE__ from './stats-body.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';

import type StatsParameters from 'embercom/objects/stats-system/stats-parameters';
import { type StatisticKeyName } from 'embercom/models/data/outbound/types';
import { type ActiveContent } from 'embercom/lib/knowledge-hub/constants';
import { _makeStatsParametersForArticle } from 'embercom/helpers/stats-system/stats-parameters';
import type ArticleContent from 'embercom/models/articles/article-content';
import { STATS_BODY_COMPONENTS } from 'embercom/lib/knowledge-hub/constants';

type OptionItem = { value: string; text: string };

interface Signature {
  Element: HTMLDivElement;
  Args: {
    statKeyName: StatisticKeyName;
    activeContent: ActiveContent;
  };
}

export default class StatsBody extends Component<Signature> {
  @service declare appService: any;
  @service declare helpCenterService: any;
  @service declare intl: IntlService;

  @tracked range: $TSFixMe;
  @tracked reaction?: string;
  @tracked localizedContentId?: number;
  @tracked helpCenterId = '';

  componentMaps = STATS_BODY_COMPONENTS;

  get statsParameters(): StatsParameters | undefined {
    return _makeStatsParametersForArticle(
      (this.args.activeContent as any).article,
      this.range,
      this.appService.app.timezone,
      this.localizedContentId,
      this.helpCenterId,
    );
  }

  // date filter

  @action onUpdateDateRange(range: $TSFixMe) {
    this.range = range;
  }

  // reaction filter

  @action changeReactionFilter(_: any, value?: string) {
    this.reaction = value;
  }

  // locale filter

  @action filterByLocalizedContentId(localizedContentId: number) {
    this.localizedContentId = localizedContentId;
  }

  get localeOptions(): OptionItem[] {
    let articleContent = this.args.activeContent as ArticleContent;
    let locales = articleContent.article.articleContents.map((articleContent) => ({
      value: articleContent.id,
      text: this.helpCenterService.getLocaleNameFromLocaleCode(articleContent.locale),
    }));

    return [{ value: '', text: this.intl.t('articles.show.stats.locale-filter.all') }, ...locales];
  }

  // help center filter

  @action filterByHelpCenterId(helpCenterId: string) {
    this.helpCenterId = helpCenterId;
  }

  get helpCenterOptions(): OptionItem[] {
    let helpCenters = this.helpCenterService.allSites.map((site: any) => ({
      value: site.id,
      text: site.name,
    }));

    return [
      {
        value: '',
        text: this.intl.t('articles.show.stats.help-center-filter.all'),
      },
      ...helpCenters,
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::StatsBody': typeof StatsBody;
    'knowledge-hub/content-editor/article-content/stats-body': typeof StatsBody;
  }
}
