/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';

interface Args {
  modal: any;
  title?: string;
  onNavigationNext?: (viewId?: string) => void;
  onNavigationBack?: () => void;
  onModalClose?: () => void;
  showBackButton?: boolean;
}

const DROPDOWN_OPTIONS_LIMIT = 30;

export default class Modal extends Component<Args> {
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare conversationExtractionService: ConversationExtractionService;

  get conversationExtractionSettings() {
    return this.conversationExtractionService.conversationExtractionSettings;
  }

  get teamIDs() {
    return this.conversationExtractionSettings?.teamIds || [];
  }

  get adminIDs() {
    return this.conversationExtractionSettings?.adminIds || [];
  }

  get adminsLabel() {
    if (this.adminIDs.length > 0) {
      return this.intl.t(
        'knowledge-hub.conversational.conversation-extraction-settings.filter.admin-settings.label.selected',
        {
          num: this.adminIDs.length,
        },
      );
    } else {
      return this.intl.t(
        'knowledge-hub.conversational.conversation-extraction-settings.filter.admin-settings.label.blank',
      );
    }
  }

  get admins() {
    let selectedAdmins = this.adminIDs;
    let requiresInboxSeatAccess = this.appService.app.requiresInboxSeatAccess;
    let admins = requiresInboxSeatAccess
      ? this.appService.app.adminsWithInboxAccess
      : this.appService.app.humanAdmins;
    return admins.map((admin: any) => ({
      text: admin.name,
      value: Number(admin.id),
      isSelected: selectedAdmins.includes(Number(admin.id)),
    }));
  }

  get teamsLabel() {
    if (this.teamIDs.length > 0) {
      return this.intl.t(
        'knowledge-hub.conversational.conversation-extraction-settings.filter.team-settings.label.selected',
        {
          num: this.teamIDs.length,
        },
      );
    } else {
      return this.intl.t(
        'knowledge-hub.conversational.conversation-extraction-settings.filter.team-settings.label.blank',
      );
    }
  }

  get teams() {
    let selectedTeams = this.teamIDs;
    let teams = this.appService.app.assignableTeams;
    return teams.map((team: any) => ({
      text: team.name,
      value: Number(team.id),
      isSelected: selectedTeams.includes(Number(team.id)),
    }));
  }

  @action async submitSettings() {
    if (!this.conversationExtractionSettings || this.isSettingsSubmitBlocked) {
      return;
    }

    await this.knowledgeHubService.ensurePermissions();

    try {
      this.conversationExtractionSettings.enabled = true;
      await this.conversationExtractionSettings.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: 'conversation_extraction_settings',
        enabled: true,
        admin_ids: this.adminIDs,
        team_ids: this.teamIDs,
        default_chatbot_availability:
          this.conversationExtractionSettings?.defaultChatbotAvailability,
        default_copilot_availability:
          this.conversationExtractionSettings?.defaultCopilotAvailability,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.update-notifications.success',
        ),
      );
      if (this.args.onModalClose) {
        this.args.onModalClose();
      }
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.update-notifications.failure',
        ),
      });
    }
  }

  get dropdownOptionsLimit() {
    return DROPDOWN_OPTIONS_LIMIT;
  }

  get isSettingsSubmitBlocked() {
    return this.conversationExtractionSettings?.isLatestImportInProgress ?? false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Conversational::Settings::Modal': typeof Modal;
  }
}
