/* import __COLOCATED_TEMPLATE__ from './side-drawer-body.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import type ContentStatistic from 'embercom/models/outbound/content-statistic';
import { type StatisticKeyName } from 'embercom/models/data/outbound/types';
import { type ActiveContent } from 'embercom/lib/knowledge-hub/constants';

export type EnumeratedStat = { key: StatisticKeyName; object: ContentStatistic | undefined };

interface Args {
  stats: EnumeratedStat[];
  selectedTab: StatisticKeyName;
  setSelectedTab: (tab: StatisticKeyName) => void;
  onClose: () => void;
  activeContent: ActiveContent;
}

export default class SideDrawerBody extends Component<Args> {
  get bodyComponentName() {
    let statsBodyComponentName = this.args.activeContent.editorConfig.statsConfig?.bodyConfig.name;
    if (!statsBodyComponentName || statsBodyComponentName === '') {
      return 'knowledge-hub/content-editor/shared/stats-body';
    }

    return statsBodyComponentName;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::ReportingSection::SideDrawerBody': typeof SideDrawerBody;
    'knowledge-hub/content-editor/shared/reporting-section/side-drawer-body': typeof SideDrawerBody;
  }
}
