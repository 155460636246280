/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    title?: string;
    hideMenuToggle?: boolean;
    isReadOnly?: boolean;
    readOnlyTooltipKey?: string;
  };
}

const Header = templateOnlyComponent<Signature>();
export default Header;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Header': typeof Header;
    'knowledge-hub/content-editor/shared/header': typeof Header;
  }
}
